// Here you can add other styles

//tr td.ativo:not(:nth-last-child(odd)) { text-decoration: line-through }

tr.inativo td:not(:last-child) {
  text-decoration: line-through;
  color: gray;
}

//tr.ativo td:not(:last-child) {
//  text-decoration: line-through
//}
.bg-light {
  background-color: #f2f4f8 !important;
}

/*== start of code for tooltips ==*/
.tool {
  cursor: help;
  position: relative;
}


/*== common styles for both parts of tool tip ==*/
.tool::before,
.tool::after {
  left: 50%;
  opacity: 0;
  position: absolute;
  z-index: -100;
}

.tool:hover::before,
.tool:focus::before,
.tool:hover::after,
.tool:focus::after {
  opacity: 1;
  transform: scale(1) translateY(0);
  z-index: 100;
}


/*== pointer tip ==*/
//.tool::before {
//  border-style: solid;
//  border-width: 1em 0.75em 0 0.75em;
//  border-color: #3E474F transparent transparent transparent;
//  bottom: 100%;
//  content: "";
//  margin-left: -0.5em;
//  transition: all .65s cubic-bezier(.84,-0.18,.31,1.26), opacity .65s .5s;
//  transform:  scale(.6) translateY(-90%);
//}
//
//.tool:hover::before,
//.tool:focus::before {
//  transition: all .65s cubic-bezier(.84,-0.18,.31,1.26) .2s;
//}


/*== speech bubble ==*/
.tool::after {
  background: #3E474F;
  border-radius: .25em;
  bottom: 180%;
  color: #EDEFF0;
  content: attr(data-tip);
  margin-left: -8.75em;
  //padding: 1em;
  transition: all .65s cubic-bezier(.84, -0.18, .31, 1.26) .2s;
  transform: scale(.6) translateY(50%);
  //width: 17.5em;
  padding: 5px 10px 5px 10px;
  //display: table;
}

.tool:hover::after,
.tool:focus::after {
  transition: all .65s cubic-bezier(.84, -0.18, .31, 1.26);
}

@media (max-width: 760px) {
  .tool::after {
    font-size: .75em;
    margin-left: -5em;
    width: 10em;
  }
}

.react-datepicker-popper, .modal.show .modal-dialog, .modal {
  z-index: 2000 !important;
}

.imagem_margem_30px {
  margin: 30px;
}

.imagem_margem_borda_preta {
  border: 1px solid black;
}

.imagem_margem_borda_branca {
  border: 1px solid white;
}

.body {
  position: relative;
}

.form-buttons {
  margin-bottom: 30px;
}

.react-datepicker__day--today, .react-datepicker__month-text--today, .react-datepicker__quarter-text--today, .react-datepicker__year-text--today {
  font-weight: bold;
  font-size: 18px;
}

/* Responsive image gallery rules begin*/

.image-gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.image-gallery > .img-container {
  flex: 1 1 auto; /* or flex: auto; */
  height: 200px;
  cursor: pointer;
  position: relative;
}

.image-gallery::after {
  content: "";
  flex-grow: 999;
}

.image-gallery .img-container img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  vertical-align: middle;
  border-radius: 5px;
}

.overlay-image-gallery {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(57, 57, 57, 0.502);
  top: 0;
  left: 0;
  transform: scale(0);
  transition: all 0.2s 0.1s ease-in-out;
  color: #fff;
  border-radius: 5px;
  /* center overlay content */
  display: flex;
  align-items: center;
  justify-content: center;
}

/* hover */
.image-gallery .img-container:hover .overlay-image-gallery {
  transform: scale(1);
}

.text-green {
  color: #3ba862;
}

.form-label {
  margin-bottom: 0;
  margin-top: 0.5rem;
  margin-left: 0.1rem;
}

.width-10 {
  width: 10%;
}

.width-20 {
  width: 20%;
}

.width-30 {
  width: 30%;
}

.width-40 {
  width: 40%;
}

.width-50 {
  width: 50%;
}

.width-60 {
  width: 60%;
}

.width-70 {
  width: 70%;
}

.width-80 {
  width: 80%;
}

.width-90 {
  width: 90%;
}

.width-100 {
  width: 100%;
}

fieldset {
  margin-top: 15px;
}

fieldset legend {
  font-size: 1.2em;
  border-bottom: 1px solid #d8dbe0;
  margin-bottom: 0;
}

.pesquisa-simples-html i {
  font-size: 25px;
}

.documento-upload-icone {
  text-align: center;
  padding: 10px;
}

.documento-upload-icone i {
  font-size: 50px;
}

.dashboard-button {
  height: 98px;
  width: 140px;
  margin: 5px;

  div {
    height: 60px;
    text-align: center;
    display: flex;
    place-content: center;
    flex-flow: row nowrap;
    align-items: center;
  }

  svg {
    height: 50px;
  }
}

.sidebar-nav .nav-title {
  margin-top: unset;
}


.btn-label {
  position: relative;
  left: -12px;
  display: inline-block;
  padding: 6px 12px;
  background: rgba(0, 0, 0, 0.15);
  border-radius: 3px 0 0 3px;
}

.btn-sm .btn-label {
  position: relative;
  left: -9px;
  display: inline-block;

  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;

  background: rgba(0, 0, 0, 0.15);
  //border-radius: 3px 0 0 3px;
}

.btn-labeled {
  padding-top: 0;
  padding-bottom: 0;
}
.hide {
  display: none;
}

//.btn {  }
